.first-section {
    background-position: center;
    background-size: cover;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-left: 5vh;

}

.second-section {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.modules-header {
    height: 9vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.modules {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    width: 100%;
    height: 43vh;
}

.inImg {
    max-width: 15vh;
}

.obj {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column
}

.clickable {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.clickable:hover {
    color: #0056b3;
    text-decoration: underline;
}

.stickyback {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    position: fixed;
    left: 15px;
    top: 47vh;
}

.stickyback:hover {
    color: #0056b3;
    text-decoration: underline;
}
