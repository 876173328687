


.form 
{
    
    padding-top:80
}

input:out-of-range {
    border: 5px solid red;
  }