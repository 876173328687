/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
    min-width: 250px;
    max-width: 250px;
    background: white;
    color: #adb5bd;
    height: calc(100vh - 60px);
    margin-left: -250px;
    transition: all 0.5s;
    position: absolute;
    z-index: 1001;
  }
  
  .sidebar.is-open {
    margin-left: 0;
    transition: .5s;
  }
  
  
  .sidebar-header {
    background: white;
    color: #adb5bd;
  }
  
  .sidebar-header h3{
    color: #fff;
    padding: 1em;
  }
  
  .sidebar ul p {
    color: #fff;
    padding: 10px;
  }
  
  .collapse.show, 
  .collapsing {
    display: flex;
    flex-direction: column;
    background: white;
  }
  
  .sideBar-item {
    /*color: #ced4da;*/
    color: #adb5bd;
    font-weight: 300;
  }
  
  .sideBar-item.open {
    background:#e9ecef;
  }
  
  .sideBar-item.active {
    background: #e9ecef !important;
  }
  
  .sideBar-item:hover {
    background: #e9ecef;
    color: #fff;
    transition: .2s;
  }
  
  .sideBar-item .svg-inline--fa {
    color: inherit;
  }
  
  li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 60%;
    top: 10%
  }

.sideBar-item {
  color: black !important;
}

.amalia-log{
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.sidebarNav {
  justify-content: start!important;
}