.pred-frame1 {
  width: 100vw !important;
  height: 65vh !important;
  border-width: 0 !important;

}
.pred-frame2 {
  width: 100vw;
  height: 29vh;
  max-height: 230px;
  background-color: #343a40!important;
  display: flex;
  flex-direction: row;
  border-width: 0 !important;
}

.frame {
  border-width: 0 !important;
}

.changeFrame{
  width: 10vw;
}

.frame {
  border-width: 0 !important;
}

.btn-circle {
  width: 20px!important;
  height: 20px!important;
  padding: 0px 5px!important;
  border-radius: 15px!important;
  text-align: center!important;
  vertical-align: middle!important;
  font-size: 12px!important;
}

.filter-box {
  background-color: #343a40;
  border-radius: 100px!important;
  opacity: 0.7;
  width:240px;
  height:50px;
  position:absolute;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  justify-content: center;
  top:60px;
  left: 70px;
}