.sticky-navbar {
    top: 0;
    height: 40px;
    width: 100%;
    padding: 0 1vw;
}

.dropdown-menu-user {
    background-color: transparent !important;
    border: none !important;
}

.dropdown-item-user:focus, .dropdown-item-user:hover {
    background-color: transparent !important;
    border: none !important;
}

.nav-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.login-btn {
    background: #15949c !important;
    border-color: #15949c !important;
}



.product-text {
    margin-top: 0px !important;
    margin-bottom: 0 !important;
    margin-left: 10px !important;
}

h4 {
    margin-bottom: 0rem !important;
}

@media only screen and (max-width: 768px) {
    .sticky-navbar {
        top: 0;
        height: 50px;
        width: 100%;
        padding: 0 1vw;
    }
}
