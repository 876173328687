.sticky-navbar-modules {
    top: 0;
    height: 4vh !important;
    min-height: 40px!important;
    width: 100%;
    padding: 0 1vw !important;
}

.filter-text {
    margin-top: auto !important;
    margin-bottom: auto !important;
    font-size: small;
    color: white;
}