.fullscreen{
    width: 100vw;
    height: 100vh;
}

.first-section-landing{
    background-size: cover !important;
    -webkit-background-size: cover!important;;
    -moz-background-size: cover!important;;
    -o-background-size: cover!important;;
    width: 100%;
    height: 95vh;
    top:0
}

.content {
    padding-left: 3rem!important;
}

.logo {
    padding-left: 3rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap
}


.image {
    width:50vw;
    padding-top: 20vh;
    z-index: 10
}

.btn-conozca {
    width: 8rem;
}

.div-social {
    width: 8rem;
    display: flex;
    justify-content: space-evenly;
}

.social-icon {
    color:#669999
}

.header-landing {
    margin-top: 10vh;
}

.key-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 64px;
    padding-bottom: 200px;
}

.keys {
    width: 30vw;
    text-align: center;
}


.second-section-landing{
    width: 100vw;
    height: 40vh;
}

.bg-secondary{
    width:100vw;
    padding-top: 50px ; 
    padding-bottom: 50px !important
}

.arch-container{
    padding: 50px 16px 1px
}

.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100vw;
    padding-bottom: 5%;
  }
  .video_wrapper {
    position: relative;
    padding-top: 28.125%;
    padding-bottom: 28.125%;
    margin-bottom:-20%
}

.videoiframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 60%;
    padding-left: 20%;
    padding-top: 0%
}