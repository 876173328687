.login-form-field::placeholder {
    color: #3a3a3a;
}

.ms-TextField-field {
    margin-bottom: 4%;
}

.form-signin {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 100px;
    width: 60%;
}

.inline-errormsg {
    color: red;
    font-size: 12px;
}

.row-cols-lg-1 {
    margin-top: 5%;
}

.row {
    justify-content: center;
}

.sign-logo{
    margin-top: 5vh;
    margin-bottom: 5vh;
    width: 30vw;
    height: 10vh;
    max-width: 400px;

}

.copyright-row {
    margin-top: 0 !important;
}
