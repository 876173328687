.frame1 {
    width: 100vw;
    height: calc(100vh - 95px);
}

iframe {
    width: 100%;
    height: 100%;
}

.recuento {
    width: 50vw;
    min-width: 100px;
    min-height: 300px;
    height: 52vh;
    z-index: 10;
    bottom: 54px;
    left: 0;
    position: absolute;
}

.dsd {
    width: 100vw;
    min-width: 600px;
    min-height: 200px;
    height: 30vh;
    z-index: 10;
    bottom: 54px;
    position: absolute;
}

.fallas {
    width: 50vw;
    height: 52vh;
    min-width: 200px;
    min-height: 200px;
    z-index: 10;
    bottom: 54px;
    right: 0;
    position: absolute;
}

.frame5{
    width: 100vw;
    height: 91.8vh; 
}

.navy {
    width: 85vw !important;
    justify-content: space-between;
}

.bottom-nav {
    height:35px;
}

.nav {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.lifeRing {
    color: #10baca !important;
}

.userCircle {
    color: #dad55e !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
    margin-left: 0;
    height: 95vh;
  }
  
  @media only screen and (max-width: 575.98px) {
    body {
      overflow:  hidden;
    }
    
    .content.is-open {
      /*margin-left: 100%;*/
      display: none;
      transition: all 0.5s;
    }
    
    .sidebar.is-open {
      min-width: 100%;
      max-width: 100%;
      margin-left: 0;
      transition: all 0.5s, height 0s;
      height: 100vh !important;
    }
    
    .sidebar.is-open > .sidebar-header button {
      display: block;
    }
    
    li a.dropdown-toggle::after {
      display: inline-block;
      position: relative;
      left: 68%;
    }
    
  }
.wrapper {
    display: flex;
    width: 100%;
    z-index: 0;
    align-items: stretch;
}